import React from "react"
import { groupBy } from "lodash"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/section"
import Container from "../../components/container"
import Typography from "../../components/typography"
import Card from "../../components/card"
import Flex from "../../components/flex"
import Button from "../../components/button"
import allDiseases from "../../api/diseases"

const groupedDiseases = groupBy(allDiseases, "type")

const Diseases = props => (
  <Layout {...props}>
    <SEO
      title="Doenças tratadas pela GastroCirúrgica"
      description="Veja as doenças tratadas e seus respectivos tratamentos e cirurgias realizadas pela equipe  GastroCirúrgica"
    />
    <Section padding="2rem 0">
      <Container>
        <Typography variant="h1" component="h1" color="secondary">
          Doenças tratadas
        </Typography>
        <Typography variant="h6" color="primary" component="h2">
          Veja as doenças tratadas e seus respectivos tratamentos e cirurgias
          realizadas pela equipe GastroCirúrgica
        </Typography>
      </Container>
    </Section>
    {Object.values(groupedDiseases).map((diseases, index) => (
      <Section
        background={index % 2 === 0 ? "secondary" : "tertiary"}
        padding="2.5rem 0"
      >
        <Container>
          <Flex flexWrap="wrap">
            {diseases.map(disease => (
              <Flex child flexXs={100} flex={50} spacing={1.5}>
                <Card padding="2rem" margin="0.75rem 0">
                  <Typography variant="h5" component="h3" color="secondary">
                    {disease.name}
                  </Typography>
                  {!disease.disabled ? (
                    <Button to={`/doencastratadas/${disease.slug}`} htmlType="link" color="link">
                      Saiba mais
                    </Button>
                  ) : (
                    <Button color="link" disabled>
                      &nbsp;
                    </Button>
                  )}
                </Card>
              </Flex>
            ))}
          </Flex>
        </Container>
      </Section>
    ))}
  </Layout>
)

export default Diseases
