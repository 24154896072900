const diseases = [
  {
    name: "Obesidade Mórbida",
    slug: "obesidade_morbida",
    type: "benignas",
  },
  {
    name: "Colelitíase (Pedra na vesícula) / Coledocolitiase",
    slug: "colelitiase",
    type: "benignas",
  },
  {
    name: "Apendicite Aguda",
    slug: "apendicite_aguda",
    type: "benignas",
  },
  {
    name: "Hérnias da Parede Abdominal",
    slug: "hernias_da_parede_abdominal",
    type: "benignas",
  },
  {
    name: "Doença do Refluxo Gastroesofágico",
    slug: "doenca_do_refluxo_gastroesofagico",
    type: "benignas",
  },
  {
    name: "Megaesôfago e Acalasia",
    slug: "megaesofago_e_acalasia",
    type: "benignas",
    disabled: true,
  },
  {
    name: "Endometriose Profunda Intestinal",
    slug: "endometriose_profunda_intestinal",
    type: "benignas",
  },
  {
    name: "Doença de Crohn e Retocolite Ulcerativa",
    slug: "doenca_de_crohn_e_retocolite_ulcerativa",
    type: "benignas",
  },
  {
    name: "Diverticulose Colônica / Diverticulite",
    slug: "diverticulose_colonica_diverticulite",
    type: "benignas",
  },
  {
    name: "Doenças do Ânus (hemorróidas, fístulas e fissuras anais)",
    slug: "doencas_do_anus_hemorroidas_fistulas_e_fissuras_anais",
    type: "benignas",
  },
  {
    name: "Cisto Pilonidal",
    slug: "cisto_pilonidal",
    type: "benignas",
  },
  {
    name: "Hidrosadenite Supurativa Perineal",
    slug: "hidrosadenite_supurativa_perineal",
    type: "benignas",
    disabled: true,
  },
  {
    name: "Reconstrução de Trânsito Intestinal e Fechamento de Colostomia",
    slug: "reconstrucao_de_transito_intestinal_e_fechamento_de_colostomia",
    type: "benignas",
  },
  {
    name:
      "Acessos Vasculares de Longa Permanência - Implante e Retirada de Portocath",
    slug:
      "acessos_vasculares_de_longa_permanencia_implante_e_retirada_de_portocath",
    type: "benignas",
    disabled: true,
  },
  {
    name: "Neoplasias Gastrointestinais",
    slug: "neoplasias_gastrointestinais",
    type: "malignas",
    disabled: true,
  },
  {
    name: "Câncer de esôfago",
    slug: "cancer_de_esofago",
    type: "malignas",
  },
  {
    name: "Câncer de estômago",
    slug: "cancer_de_estomago",
    type: "malignas",
  },
  {
    name: "Neoplasias duodenal e Pancreática",
    slug: "neoplasias_duodenal_e_pancreática",
    type: "malignas",
  },
  {
    name: "Neoplasias e Metástases Hepáticas",
    slug: "neoplasias_e_metastases_hepaticas",
    type: "malignas",
  },
  {
    name: "Câncer colorretal",
    slug: "cancer_colorretal",
    type: "malignas"
  },
  {
    name: "Tumores anorretais",
    slug: "tumores_anorretais",
    type: "malignas",
  },
  {
    name: "Tumores de parede abdominal e retroperitoniais",
    slug: "Tumores de parede abdominal e retroperitoniais",
    type: "malignas",
    disabled: true,
  },
]

module.exports = diseases
